import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function Gallery() { 
	return( 
	<>  
    
      <Header /> 

      <main> 
            <div class="page-title-area section-notch pt-50 pb-50">
                <div class="banner-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="page-title">
                                <h2>Gallery</h2>
                                <div class="breadcrumb-list text-left">
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li>Gallery</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
             
            <div class="portfolio-area pt-110 pb-80" id="gallery">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6 col-md-9">
                            <div class="section-title text-center mb-50">
                                <h2 class="title">Our Gallery</h2>
                                <p>Here is what you can expect from a house cleaning from a Handy professional. Download the app
                                    to share further cleaning details and instructions!</p>
                            </div>
                        </div>
                    </div> 
                    <div class="row grid text-center">
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/1.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/1.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/2.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/2.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/3.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/3.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/4.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/4.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/5.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/5.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/6.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/6.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/7.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/7.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/8.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/8.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/9.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/9.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/10.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/10.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/11.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/11.jpg"><i
                                                class="icon fal fa-plus"></i></a>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/12.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/12.jpg"><i class="icon fal fa-plus"></i></a>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/13.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/13.jpg"><i class="icon fal fa-plus"></i></a>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/14.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/14.jpg"><i class="icon fal fa-plus"></i></a>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/15.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/15.jpg"><i class="icon fal fa-plus"></i></a>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/16.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/16.jpg"><i class="icon fal fa-plus"></i></a>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 grid-item mb-30 ">
                            <div class="portfolio-item">
                                <div class="fortfolio-thumb">
                                    <img src="../assets/images/portfolio/17.jpg" alt="" />
                                </div>
                                <div class="portfolio-content">
                                    <div class="content-view">
                                        <a class="popup-image" href="../assets/images/portfolio/17.jpg"><i class="icon fal fa-plus"></i></a>
                                    </div>                                    
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div> 
        </main>
      
      <Footer/>

  </> 
  ); 
  } 
  export default Gallery;