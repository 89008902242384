import React from 'react';

function  Header () {
  return (
  <>
  
        <header class="header-area">
            <div class="top_section flw">
                <div class="container">
                  <div class="row">
                    <div class="top_box flw">
                        <ul class="top_list">
                            <li class="spc"><i class="fa-solid fa-phone"></i><a href="#">04633-280251, 8686547777 </a></li>
                            <li class="spc"><i class="fa-solid fa-envelope"></i><a href="mailto:asavidhyaniketanschool@gmail.com">asavidhyaniketanschool@gmail.com</a></li>
                        </ul>
                        <ul class="top_social">
                            <li><a href="#" class="ion-social-facebook" target="_blank"><i class="fa-brands fa-facebook"></i></a></li>
                            <li><a href="#" class="ion-social-linkedin" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
                            <li><a href="#" class="ion-social-instagram" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>                        
                    {/* <div class="online">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-12 text-right">
                                    <a href="../assets/images/PSM_College_Application.pdf" target="_blank">Download Applications</a>
                                </div>
                            </div>
                        </div>
                    </div>                       */}
                    
                  </div>
                </div>
              </div>
            
            <div class="header-bottom-area" data-uk-sticky="top: 250; animation: uk-animation-slide-top;">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-3 col-lg-3 col-10">
                            <div class="logo">
                                <a href="/"><img src="../assets/images/logo.png" alt="" /></a>
                            </div>
                        </div>
                        <div class="col-xl-9 col-lg-9 d-none d-lg-block">
                            <div class="main-menu-wrap">
                                <nav id="mobile-menu" class="main-menu">
                                    <ul class="main-menu-list ul_li">
                                        <li><a href="/">Home</a></li>
                                        <li><a href="/AboutUs">About Us</a></li>
                                        <li><a href="#">Academics</a></li>
                                        <li><a href="#">Admission</a></li>
                                        <li><a href="#">Campus Life </a></li>
                                        {/* <li><a href="#">Course</a></li> */}
                                        {/* <li><a href="/Facilities">Facilities</a></li> */}
                                        <li><a href="/Gallery">Gallery</a></li> 
                                        {/* <li class="menu-item-has-children"><a href="#">Mandate</a>
                                            <ul class="submenu">
                                                <li><a href="#">NCTE Approval</a></li>
                                                <li><a href="#">TNTEU Affiliation</a></li>
                                                <li><a href="#">B.Ed. Syllabus</a></li>
                                                <li><a href="#">B.Ed. Admission Norms</a></li> 
                                            </ul>
                                        </li>  */}
                                        <li><a href="/ContactUs">Contact Us</a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-2">
                            <div class="header-right">
                                <div class="side-mobile-menu">
                                    <button class="side-info-close"><i class="fa fa-times"></i></button>
                                    <div class="mobile-menu"></div>
                                </div>
                                <div class="side-menu-icon d-lg-none">
                                    <button class="side-toggle"><i class="fa fa-bars"></i></button>
                                </div>
                                <div class="offcanvas-overlay"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header> 

  </>
);
}

export default Header;