
function Banner() { 
	return( 
	<>
    
    <section class="hero-slider hero-style-1 section-notch">
                <div class="swiper-container">
                    <div class="swiper-wrapper"> 
                        <div class="swiper-slide">
                            <div class="slide-inner slide-overlay slide-bg-image">
                                <div class="banner-bg-images">
                                    <img src="../assets/images/slider/slider1.jpg" alt="" />
                                </div>
                                <div class="container">                                
                                    <div data-swiper-parallax="300" class="slide-title">
                                        <h2>An investment in knowledge pays the best interest </h2>
                                    </div>
                                    <div data-swiper-parallax="400" class="slide-text">
                                        <p>You can have it all Teacher Training education that broadens your mind, the chance to serve your community</p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="slider-btn">
                                        <a data-swiper-parallax="500" class="thm-btn thm-btn-2" href="#">Our Course</a>
                                        <a data-swiper-parallax="550" class="thm-btn" href="/ContactUs">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="slide-inner slide-overlay slide-bg-image">
                                <div class="banner-bg-images">
                                    <img src="../assets/images/slider/slider2.jpg" alt="" />
                                </div>
                                <div class="container"> 
                                    <div data-swiper-parallax="300" class="slide-title">
                                        <h2>Our library is stocked with 5700 B.Ed books</h2>
                                    </div>
                                    <div data-swiper-parallax="400" class="slide-text">
                                        <p>Library is the heart of Educational Institutions. The main aim of library is to provide right documents to right users at right time.</p>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div class="slider-btn">
                                    <a data-swiper-parallax="500" class="thm-btn thm-btn-2" href="#">Our Course</a>
                                        <a data-swiper-parallax="550" class="thm-btn" href="/ContactUs">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div> 
                    <div class="container">
                        <div class="swiper-button-next"><i class="fa fa-chevron-right"></i></div>
                        <div class="swiper-button-prev"><i class="fa fa-chevron-left"></i></div>
                    </div>
                </div>
            </section> 
    
        
  </> 
  ); 
  } 
  export default Banner;