import Header from '../../components/Header';  
import Footer from '../../components/Footer';
function ContactUs() { 
	return( 
	<>  
    
      <Header /> 
      <main> 
            <div class="page-title-area section-notch pt-50 pb-50">
                <div class="banner-overlay"></div>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="page-title">
                                <h2>Contact Us</h2>
                                <div class="breadcrumb-list text-left">
                                    <ul>
                                        <li><a href="#">Home</a></li>
                                        <li>Contact Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
             
            <section class="ttm-row zero-padding-section clearfix">
                <div class="container">
                    <div class="row no-gutters"> 
                        <div class="col-lg-5">
                            <div class="spacing-9"> 
                                <div class="section-title with-desc clearfix">
                                    <div class="title-header">
                                        <h5>Come Visit Us At</h5>
                                        <h2 class="title">Our Address</h2>
                                    </div>
                                </div> 
                                <div class="row">
                                    <div class="col-12"> 
                                        <div class="featured-icon-box style2 left-icon icon-align-top">
                                            <div class="featured-icon"> 
                                                <div class="ttm-icon ttm-icon_element-bgcolor-skincolor ttm-icon_element-size-md ttm-icon_element-style-round">
                                                    <i class="fa fa-phone"></i>
                                                </div>
                                            </div>
                                            <div class="featured-content">
                                                <div class="featured-title"> 
                                                    <h5>Contact Number</h5>
                                                </div>
                                                <div class="featured-desc"> 
                                                    <p>04633-28025, 8686547777 </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="separator">
                                    <div class="sep-line mt-20 mb-20"></div>
                                </div> 
                                <div class="row">
                                    <div class="col-12"> 
                                        <div class="featured-icon-box style2 left-icon icon-align-top">
                                            <div class="featured-icon"> 
                                                <div class="ttm-icon ttm-icon_element-bgcolor-skincolor ttm-icon_element-size-md ttm-icon_element-style-round">
                                                <i class="fab fa-whatsapp"></i>
                                                </div>
                                            </div>
                                            <div class="featured-content">
                                                <div class="featured-title"> 
                                                    <h5>Whats App Number:</h5>
                                                </div>
                                                <div class="featured-desc"> 
                                                    <p>8686547777</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="separator">
                                    <div class="sep-line mt-20 mb-20"></div>
                                </div> 
                                <div class="row">
                                    <div class="col-12"> 
                                        <div class="featured-icon-box style2 left-icon icon-align-top">
                                            <div class="featured-icon"> 
                                                <div class="ttm-icon ttm-icon_element-bgcolor-skincolor ttm-icon_element-size-md ttm-icon_element-style-round">
                                                <i class="far fa-envelope"></i>
                                                </div>
                                            </div>
                                            <div class="featured-content">
                                                <div class="featured-title"> 
                                                    <h5>Email</h5>
                                                </div>
                                                <div class="featured-desc"> 
                                                    <p>asavidhyaniketanschool@gmail.com</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="separator">
                                    <div class="sep-line mt-20 mb-20"></div>
                                </div> 
                                <div class="row">
                                    <div class="col-12"> 
                                        <div class="featured-icon-box style2 left-icon icon-align-top">
                                            <div class="featured-icon"> 
                                                <div class="ttm-icon ttm-icon_element-bgcolor-skincolor ttm-icon_element-size-md ttm-icon_element-style-round">
                                                <i class="fas fa-map-marker-alt"></i>
                                                </div>
                                            </div>
                                            <div class="featured-content">
                                                <div class="featured-title"> 
                                                    <h5>Address</h5>
                                                </div>
                                                <div class="featured-desc"> 
                                                    <p>ASA VIDHYA NIKETAN SCHOOL ( CBSE)<br/>
Ayikudi Road, Tenkasi-627811.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                               
                                
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                                <div class="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                    <div class="ttm-bg-layer-inner"></div>
                                </div> 
                                <div class="section-title with-desc clearfix">
                                    <div class="title-header">
                                        <h5>Send Message</h5>
                                        <h2 class="title">Drop Us A Line</h2>
                                    </div>
                                </div> 
                                <form id="ttm-quote-form" class="row ttm-quote-form clearfix" method="post" action="#">
                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-group">
                                            <input name="name" type="text" class="form-control bg-white" placeholder="Full Name*" required="required"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-group">
                                            <input name="phone" type="text" placeholder="Phone Number*" required="required" class="form-control bg-white"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-group">
                                            <input name="address" type="text" placeholder="Email Address*" required="required" class="form-control bg-white"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 col-md-6">
                                        <div class="form-group">
                                            <input name="subject" type="text" placeholder="Subject" required="required" class="form-control bg-white"/>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12">
                                        <div class="form-group">
                                            <textarea name="Massage" rows="5" placeholder="Write A Massage..." required="required" class="form-control bg-white"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="text-left">
                                            <button type="submit" id="submit" class="thm-btn" value="">
                                                Submit 
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>  
                </div>
            </section>
            <section class="prt-row padding_zero-section overflow-hidden clearfix">
                <div class="container-fuild">
                    <div class="row">
                        <div class="col-lg-12 p-0">
                            <div id="google_map" class="google_map">
                                <div class="map_container">
                                    <div id="map">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1970.4638617463045!2d77.32555602242098!3d8.978798403139308!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b06826339449849%3A0xf3247ef2e99ec75!2sTenkasi%20Ayikudi%20Rd%2C%20Padikkattupalli%2C%20Tenkasi%2C%20Tamil%20Nadu%20627811!5e0!3m2!1sen!2sin!4v1714998124274!5m2!1sen!2sin"height="590" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
      <Footer/>

  </> 
  ); 
  } 
  export default ContactUs;